/* Custom style for the Google Translate widget */
.custom-translate {
    /* position: fixed; */
    top: 20px;
    right: 20px;
    z-index: 999;
}

/* Remove Google Translate toolbar and banner */
.skiptranslate iframe {
    display: none !important;
}

body {
    top: 0px !important;
}

/* Customize the dropdown */
.goog-te-gadget-simple {
    background-color: transparent !important;
    border: none !important;
    padding: 0 !important;
    font-size: 1rem !important;
    font-family: Arial, sans-serif !important;
    color: #000 !important;
}

.goog-te-combo {
    font-size: 16px !important;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 5px;
    color: #333;
    background-color: #fff;
    appearance: none;
}

.skiptranslate.goog-te-gadget>div+* {
    display: none;
}

.skiptranslate.goog-te-gadget {
    font-size: 0 !important;
}

.goog-te-gadget .goog-te-combo {
    margin: 4px 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    border-radius: 4px;
    padding: 0 18px;
    height: 42px;
    line-height: 42px;
    border-radius: 20px;
    width: 100%;
    border: 1px solid var(--border-color);
    cursor: pointer;
}
.languageselectfieldd form > div label + div {
   display: none;    
}