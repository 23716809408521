@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800;900&display=swap');


:root {
  --primary-color: #558B2F;
  --primary-color10: #558b2f1a;
  --primary-color30: #558b2f4d;
  --primary-gradient: radial-gradient(circle, rgba(85, 139, 47, 1) 0%, rgba(23, 37, 13, 1) 100%);
  --white-color: #ffffff;
  --light-black-color: #57644B;
  --white-light-color: #F8F9F8;
  --white-extra-light-color: #F8FEF4;
  --grey-color: #75846B;
  --border-color: rgba(85, 139, 47, 0.5);
}

/* Hide scrollbars on all elements */
* {
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
}

/* For WebKit browsers (Chrome, Safari, etc.) */
*::-webkit-scrollbar {
  display: none;
}

body {
  font-family: 'Manrope', sans-serif;
  background-color: var(--white-color);
  line-height: 1.5;
}

body a,
body a.MuiTypography-root {
  color: var(--primary-color);
  text-decoration-color: var(--border-color);
  position: relative;
}

body .text-grey {
  color: var(--grey-color);
}

body h1.title {
  font-size: 46px;
  line-height: 1.5;
  font-weight: 700;
  font-family: 'Manrope', sans-serif;
}

body h2.title {
  font-size: 36px;
  line-height: 1.5;
  font-weight: 700;
  font-family: 'Manrope', sans-serif;
}

body h3.title {
  font-size: 30px;
  line-height: 1.5;
  font-family: 'Manrope', sans-serif;
}

body h4.title {
  font-size: 24px;
  line-height: 1.5;
  font-weight: 700;
  font-family: 'Manrope', sans-serif;
}

body h5.title {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 700;
  font-family: 'Manrope', sans-serif;
}

body h6.title {
  font-size: 16px;
  line-height: 1.5;
  font-family: 'Manrope', sans-serif;
}

body .fw-400 {
  font-weight: 400 !important;
}

body .fw-500 {
  font-weight: 500 !important;
}

body .fw-600 {
  font-weight: 600 !important;
}

body .fw-700 {
  font-weight: 700 !important;
}

body .fw-800 {
  font-weight: 800 !important;
}

.text-white {
  color: var(--white-color);
}

.theme-black-color {
  color: var(--light-black-color);
}

.text-primary {
  color: var(--primary-color);
}

button.btn-text {
  background-color: transparent;
  color: var(--light-black-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  padding: 0;
  text-transform: capitalize;
}

button.btn-text .MuiButton-startIcon svg {
  width: 20px;
  height: auto;
  max-height: 20px;
}

button.btn-text:hover,
button.btn-text.text-primary {
  color: var(--primary-color);
}

.MuiFormControlLabel-root .MuiCheckbox-root {
  padding-right: 5px;
}

.MuiFormControlLabel-root .MuiCheckbox-root {
  color: var(--primary-color);
}

.MuiFormControlLabel-root .MuiCheckbox-root.Mui-checked {
  color: var(--primary-color);
}

.MuiFormControlLabel-root .MuiCheckbox-root~.MuiTypography-root {
  color: var(--grey-color);
}

.success-list-wrapper .list-success {
  padding: 0;
}

.success-list-wrapper .list-success .MuiListItem-root {
  padding: 0px 0px 0px 20px;
  position: relative;
  font-size: 12px;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 5px;
  font-family: 'Manrope', sans-serif;
}

.success-list-wrapper .list-success .MuiListItem-root::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 0px;
  width: 15px;
  height: 15px;
  background: url("./assets/images/icon/success-icon.svg");
}

.list-style-list .MuiList-root {
  margin: 0;
  padding: 0 0 0 15px;
  list-style-type: disc;

}

.list-style-list .MuiList-root .MuiListItem-root {
  display: list-item;
  padding: 0;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Manrope', sans-serif;
}

label .upload-btn {
  width: 100%;
  display: block;
  padding: 22px;
  background: var(--white-light-color);
  border-radius: 10px;
  text-align: center;
  color: var(--primary-color);
  box-shadow: none;
  font-size: 18px;
  font-weight: 600;
  text-transform: none;
  font-family: 'Manrope', sans-serif;
}

label .upload-btn svg {
  margin-top: 20px;
}

/* Loading CSS */

.loading-body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 500px;
  background: var(--primary-gradient);
}

.loading-container {
  width: 150px;
  height: 150px;
  display: flex;
  place-content: center;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 30px;
}

.item {
  width: 100%;
  overflow: visible;
  stroke: #fff;
  stroke-width: 3;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-align-left {
  text-align: left !important;
}

.MuiOutlinedInput-root {
  padding: 0 !important;
  width: 100%;

  .MuiInputAdornment-root {
    display: none;
  }
}

.iconTextField {
  .MuiOutlinedInput-root {
    padding: 0;

    .MuiInputAdornment-outlined {
      position: absolute;
      right: 20px;
    }

    .MuiInputAdornment-root {
      display: block;
    }
  }
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf.VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc {
  display: none !important;
}

.VIpgJd-yAWNEb-L7lbkb {
  display: none !important;
}