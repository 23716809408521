.error-boundary {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--white-extra-light-color);
    padding: 20px;
    text-align: center;
}

.error-boundary-content {
    max-width: 500px;
    background: var(--primary-color10);
    padding: 40px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.error-boundary .title {
    font-size: 30px;
    font-weight: 700;
    color: var(--primary-color);
    margin-bottom: 16px;
}

.error-boundary .text-grey {
    font-size: 16px;
    color: var(--grey-color);
    margin-bottom: 24px;
}

.error-boundary .refresh-button {
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 600;
    color: var(--white-color);
    background-color: var(--primary-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.error-boundary .refresh-button:hover {
    background-color: var(--primary-color30);
}